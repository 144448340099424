import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron,
  CMedia,
  CInlineDefinition,
  CBtnList,
  CBreadCrumb,
  CSearch,
} from '../../../components/_index';
import '../../../assets/_sass/page/tourism.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: '観光に便利なサービス',
            sub: 'INFORMATION',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/tourism/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/tourism/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>

      <div className="p_tourism">
        <section className="u_pt100 u_pb120">
          <LWrap>
            <CMedia
              data={[
                {
                  align: 'start',
                  img: {
                    src: '/assets/images/about/tourism/img_tourism.png',
                    alt: '',
                  },
                  title: '無料巡回バス「メトロリンク日本橋Eライン」運行',
                  text: (
                    <>
                      東京駅八重洲口から浜町・人形町・兜町エリアを結ぶ無料巡回バス「メトロリンク日本橋Eライン」。ロイヤルパークホテル正面玄関が「地下鉄水天宮前駅」として停留所となっております。歴史的魅力溢れる日本橋をバスで巡ってみませんか。
                      <ul className="c_noteList u_mt10">
                        <li>詳細は公式サイトをご確認ください。</li>
                      </ul>
                    </>
                  ),
                  btn: {
                    label: <>メトロリンク日本橋Eライン</>,
                    link: {
                      href: 'https://www.hinomaru-bus.co.jp/free-shuttle/nihonbashi_E/',
                      blank: true,
                    },
                    color: 'bgWhite02',
                  },
                },
                {
                  align: 'start',
                  img: {
                    src: '/assets/images/about/tourism/img_tourism02.png',
                    alt: '',
                  },
                  title: '中央区コミュニティバス「江戸バス」運行',
                  text: (
                    <>
                      中央区コミュニティバス（愛称名「江戸バス」）の北循環ルートにロイヤルパークホテル正面玄関「蛎殻町二丁目（ロイヤルパークホテル）」のバス停（土・日・祝日のみ運行）がございます。お出かけの際は、ぜひご利用ください。
                      <ul className="c_noteList u_mt10">
                        <li>
                          1Fフロントにて「1日乗車券」、「回数券」、「1か月定期券」の販売を行っております。
                        </li>
                        <li>詳細は中央区の公式サイトをご確認ください。</li>
                      </ul>
                    </>
                  ),
                  btn: {
                    label: <>中央区コミュニティバス（江戸バス）運行情報</>,
                    link: {
                      href: 'https://www.city.chuo.lg.jp/kurashi/koutsuu/koukyoukoutsuu/edobasu/index.html',
                      blank: true,
                    },
                    color: 'bgWhite02',
                  },
                },
                {
                  align: 'start',
                  img: {
                    src: '/assets/images/about/tourism/img_tourism03.png',
                    alt: '',
                  },
                  title: (
                    <>
                      自転車シェアリングプログラム
                      <br className="u_pc" />
                      “中央区コミュニティサイクル”
                    </>
                  ),
                  text: (
                    <>
                      都内に点在しているポート間を自由に乗り降りできる、中央区コミュニティサイクルのポートをホテル敷地内に設置しております。中央区内だけでなく、千代田区、港区、新宿区、江東区、文京区のポートへの移動も可能です。都内観光の足としてお気軽にご利用ください。
                      <br />
                      1Fフロントにて1日パスの販売を行っております。ご購入希望の際はお気軽にスタッフまでお声がけください。
                      <ul className="c_noteList u_mt10">
                        <li>詳細は中央区の公式サイトをご確認ください。</li>
                      </ul>
                    </>
                  ),
                  btn: {
                    label: <>中央区コミュニティサイクル</>,
                    link: {
                      href: 'https://www.city.chuo.lg.jp/kurashi/koutsuu/communitycycle/index.html',
                      blank: true,
                    },
                    color: 'bgWhite02',
                  },
                },
                {
                  align: 'start',
                  img: {
                    src: '/assets/images/about/tourism/img_tourism05.png',
                    alt: '',
                  },
                  title: 'Tokyo Subway Ticket販売',
                  text: (
                    <>
                      ご宿泊のお客様向けに1Fフロント、20Fエグゼクティブラウンジにて「Tokyo
                      Subway
                      Ticket（24時間、48時間、72時間）」の販売を行っております。有効期限内の使用開始から24時間・48時間・72時間に限り東京メトロ線全線および都営地下鉄線全線がご利用になれる便利な乗車券です。
                      <CInlineDefinition
                        exClass="u_mt25 u_mb25"
                        col={1}
                        data={[
                          {
                            title: '料金',
                            text: (
                              <>
                                Tokyo Subway 24-hour Ticket{' '}
                                <br className="u_sp" />
                                大人：800円 子供：400円
                                <br />
                                Tokyo Subway 48-hour Ticket{' '}
                                <br className="u_sp" />
                                大人：1,200円 子供：600円
                                <br />
                                Tokyo Subway 72-hour Ticket{' '}
                                <br className="u_sp" />
                                大人：1,500円 子供：750円
                              </>
                            ),
                          },
                          {
                            title: '有効区間',
                            text: <>東京メトロ線全線および都営地下鉄線全線</>,
                          },
                        ]}
                      />
                      <ul className="c_noteList u_mt10">
                        <li>ご宿泊のお客様に限りご提供させていただきます。</li>
                        <li>料金には消費税が含まれます。</li>
                      </ul>
                    </>
                  ),
                  btn: {
                    label: <>Tokyo Subway Ticket</>,
                    link: {
                      href: 'https://www.tokyometro.jp/tst/jp/',
                      blank: true,
                    },
                    color: 'bgWhite02',
                  },
                },
                {
                  align: 'start',
                  img: {
                    src: '/assets/images/about/tourism/img_tourism06.png',
                    alt: '',
                  },
                  title: '東京スカイツリー®入場券販売',
                  text: (
                    <>
                      1Fフロント、20Fエグゼクティブラウンジにて、東京スカイツリー天望デッキ（350m）と天望回廊（450m）の入場券の販売を行っております。当日券よりお得に購入ができ、スムーズに入場可能な便利な入場券です。
                      <br />
                      料金については、公式サイトの料金ページより前売り券情報をご確認ください。
                      <ul className="c_noteList u_mt10">
                        <li>入場券は東京スカイツリー4Fチケットカウンターにて引き換えが必要です。</li>
                      </ul>
                    </>
                  ),
                  btn: {
                    label: <>東京スカイツリー®</>,
                    link: {
                      href: 'https://www.tokyo-skytree.jp/',
                      blank: true,
                    },
                    color: 'bgWhite02',
                  }
                },
              ]}
            />

            <CBtnList
              data={[
                {
                  label: 'ホテル周辺のご案内',
                  color: 'borderBlack',
                  link: {
                    href: '/about/surroundings/',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
      </div>

      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'ホテルについて',
              path: '/about/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <CSearch formId="global" />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
